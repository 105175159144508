import { useState, useMemo } from 'react'
import {
  VStack,
  Button,
  Flex,
  Text,
  Switch,
  Image,
  Box,
  Tag
} from '@chakra-ui/react'
import NavBar from 'components/NavBar'
import { dbSyncHeyGenAvatars, dbUpdateAvatar } from 'controllers/settings'
import { useSelector } from 'model/hooks'
import _ from 'lodash'

const Avatars = () => {
  const [syncing, setSyncing] = useState(false)
  const avatarsRaw = useSelector(state => state.avatars)

  const avatars = useMemo(() => {
    return _.orderBy(_.values(avatarsRaw), 'createdAt', 'asc')
  }, [avatarsRaw])

  console.log('avatars', avatars)

  const onSyncAvatarsClick = async () => {
    setSyncing(true)
    try {
      await dbSyncHeyGenAvatars()
    } catch (error) {
      console.error('Error syncing avatars:', error)
    } finally {
      setSyncing(false)
    }
  }

  const toggleAvatarStatus = (avatarId: string) => {
    // Logic to toggle avatar's isDisabled status
    console.log('toggleAvatarStatus', avatarId)
    const isDisabled = _.get(avatarsRaw, [avatarId, 'isDisabled'], false)
    dbUpdateAvatar(avatarId, !isDisabled)
  }

  const actions = (
    <Button
      variant={'outline'}
      colorScheme='white'
      size='sm'
      onClick={onSyncAvatarsClick}
      isLoading={syncing}
    >
      Fetch HeyGen avatars
    </Button>
  )

  const renderContent = () => {
    return avatars.map(avatar => {
      let badgeColor
      switch (avatar.gender) {
        case 'male':
          badgeColor = 'blue'
          break
        case 'female':
          badgeColor = 'pink'
          break
        default:
          badgeColor = 'gray'
      }

      return (
        <Flex
          key={avatar.id}
          alignItems='center'
          justifyContent='space-between'
          w='full'
          p={2}
          borderBottom='1px solid #e2e8f0'
        >
          <Flex alignItems='center' gap={4}>
            <Box w='220px' h='100px' overflow='hidden' flexShrink={0}>
              <Image
                boxSize='100%'
                objectFit='contain'
                src={avatar.imageUrl}
                alt={avatar.name}
              />
            </Box>
            <VStack w='full' align={'start'} spacing={1}>
              <Text>{avatar.name}</Text>
              <Tag size='sm' variant='outline' colorScheme={badgeColor}>
                {avatar.gender}
              </Tag>
              <Text fontSize='xs' color='gray.500'>
                ID: {avatar.id}
              </Text>
            </VStack>
          </Flex>
          <Switch
            isChecked={!_.get(avatar, 'isDisabled', false)}
            onChange={() => toggleAvatarStatus(avatar.id)}
          />
        </Flex>
      )
    })
  }

  return (
    <VStack w='full' h='full' bg='white' overflow={'hidden'}>
      <NavBar title={'Avatars'} actions={actions} />
      <VStack w='full' overflow={'auto'} p={6}>
        {renderContent()}
      </VStack>
    </VStack>
  )
}

export default Avatars
