const conf = {
  backendUrl: 'https://api.facesign.ai',
  isDev: false,
  apiKey: 'AIzaSyBuvQx9lhBUBkfe6YUdOtOQKcDfk5T3pNg',
  authDomain: 'facesignai.firebaseapp.com',
  projectId: 'facesignai',
  storageBucket: 'facesignai.appspot.com',
  messagingSenderId: '612206086912',
  appId: '1:612206086912:web:15b41c45bb089e522001e5',
  measurementId: 'G-GZFHFXWBPM',
  algoliaAppId: 'VTOK70EGPB',
  algoliaSearchKey: 'ac2727f7de7255494958d2f97c851641',
  algoliaUsersIndex: 'prod_facesign_users',
  algoliaDemo2SessionsIndex: 'prod_demo2_sessions',
  algoliaCreateDemoSessionsIndex: 'prod_sessions',
  vegasUsersBucket: 'gs://facesignai-vegas-users'
}

module.exports = conf
